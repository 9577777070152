<template>
  <div class="card-header border-bottom pt-1 pb-1">
    <h4 class="card-title">Employment</h4>
  </div>

  <div class="card-body mt-2">
    <form class="invoice-repeater" @submit.prevent="$emit('storeContact', {'items':employment})">
      <div class="row">
        <div class="col-sm-6 offset-md-3 col-12">
          <div class="row"  v-for="(emp, index) in employment">
            <div class="col-12">
              <div class="mb-1 row">
                <label class="col-form-label">Branch</label>
                <v-select
                    placeholder="Select Branch"
                    v-model="emp.employment_branch_id"
                    :options="branchList"
                    label="name"
                    :reduce="name => name.id"
                />
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <label class="col-form-label">Division</label>
                <v-select
                    placeholder="Select Division"
                    v-model="emp.employment_division_id"
                    :options="divisionList"
                    label="name"
                    :reduce="name => name.id"
                />
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <label class="col-form-label">Department</label>
                <v-select
                    placeholder="Select Department"
                    v-model="emp.employment_department_id"
                    :options="departmentList"
                    label="name"
                    :reduce="name => name.id"
                />
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <label class="col-form-label">Business Unit</label>
                <v-select
                    placeholder="Select Business Unit"
                    v-model="emp.employment_business_unit_id"
                    :options="businessList"
                    label="name"
                    :reduce="name => name.id"
                />
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <label class="col-form-label">Date Of Joining</label>
                <input type="date"  class="form-control" v-model="emp.employment_date_of_joining"
                       placeholder="Date Of Joining" />
              </div>
            </div>


            <div class="col-12">
              <div class="mb-1 row">
                <label class="col-form-label">Effective Date</label>
                <input type="date" id="full-name" required class="form-control" v-model="emp.employment_effective_date"
                       placeholder="Effective Date" />
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <label class="col-form-label">Remarks</label>
                <input type="text" id="full-name" class="form-control" v-model="emp.employment_remarks"
                       placeholder="Remarks" />
              </div>
            </div>

            <div class="col-sm-6 offset-sm-3">
              <button type="submit" class="btn btn-primary me-1">Submit</button>
              <button @click="reset" type="reset" class="btn btn-outline-secondary">Reset</button>
            </div>

          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import {defineProps, onMounted, ref, watch} from "vue";
import handleBranches from "@/services/modules/procurement/organization-settings/branch";
import {useRoute} from "vue-router";
import handleDepartments from "@/services/modules/procurement/organization-settings/department";
import handleCBusinesses from '@/services/modules/businesses'
import handleHospital from '@/services/modules/hospital'

const props = defineProps({
  items: {
    type: Array
  }
})

const route = useRoute()
const { fetchBranchList } = handleBranches()
const { fetchDepartmentList } = handleDepartments()
const { fetchBusinessList } = handleCBusinesses()
const { fetchNoteMasterList } = handleHospital()

let departmentList = ref([])
let branchList = ref([])
let businessList = ref([])
let divisionList = ref([])

const employment  = ref([
    {
      employment: 'employment',
      employment_date_of_joining: '',
      employment_division_id:'',
      employment_branch_id:'',
      employment_department_id:'',
      employment_business_unit_id:'',
      employment_effective_date:'',
      employment_remarks:'',
    }
])

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}
const getBranchList = async () => {
  let query = getQuery();

  branchList.value = []

  await fetchBranchList(query).then(res => {
    if (!res.status){
      branchList.value = []
      return
    }
    branchList.value = res.data;
  }).catch(err => {
    console.log(err)
  })
}

const getDivisionList = async () => {
  let query = getQuery();
  query += '&is_all=true&status=active&note_type=division'

  divisionList.value = []

  await fetchNoteMasterList(query).then(res => {
    if (!res.status){
      divisionList.value = []
      return
    }
    divisionList.value = res.data;
  }).catch(err => {
    console.log(err)
  })
}

const getDepartmentList = async () => {
  let query = getQuery();

  departmentList.value = []

  await fetchDepartmentList(query).then(res => {
    if (!res.status){
      departmentList.value = []
      return
    }
    departmentList.value = res.data;
  }).catch(err => {
    console.log(err)
  })
}

const getBusinessList = async () => {
  let query = getQuery();

  businessList.value = []

  await fetchBusinessList(query).then(res => {
    if (!res.status){
      businessList.value = []
      return
    }
    businessList.value = res.data;
  }).catch(err => {
    console.log(err)
  })
}

const reset = () => {
  employment .value.length = 0;
}

watch(() => props.items, (newFormData) => {
  formDataSet();
});

const formDataSet = () => {
    if (props.items.length){
      employment.value = props.items.map(item => ({
        ...item,
        employment: item.employment,
        employment_date_of_joining:item.employment_date_of_joining,
        employment_division_id: parseInt(item.employment_division_id),
        employment_branch_id: parseInt(item.employment_branch_id),
        employment_department_id: parseInt(item.employment_department_id),
        employment_business_unit_id: parseInt(item.employment_business_unit_id),
        employment_effective_date: item.employment_effective_date,
        employment_remarks: item.employment_remarks,
      }));
    }
}

onMounted(() => {
  getBranchList();
  getDepartmentList();
  getBusinessList();
  getDivisionList();
})

</script>
