<template>
  <div class="card-header border-bottom pt-1 pb-1">
    <h4 class="card-title">Phone No</h4>
  </div>

  <div class="card-body mt-2">
    <form class="invoice-repeater" @submit.prevent="$emit('storeContact', {'items':phoneNumbers})">
      <div>

        <div v-for="(phone, index) in phoneNumbers" :key="index" class="row">
          <!-- <div class="col col-md-2 col-lg-2"></div> justify-content-md-center-->
          <div class="col-md-4 col-10">
            <div class="mb-1">
              <input v-model="phone.phone" type="text" class="form-control" id="phone" placeholder="Phone"/>
            </div>
          </div>
          <div class="col-md-2 col-2 p_left_0">
            <div class="mb-1">

              <button v-if="phoneNumbers.length === (index+1)" @click="addMore"
                      class="btn btn-outline-primary text-nowrap px-1 button_icon" type="button">
                 <i class="fa fa-plus"></i>
              </button>

              <button v-else @click="remove(index)" class="btn btn-outline-danger text-nowrap px-1 button_icon"
                      type="button">
                <i class="fa fa-trash"></i>
              </button>

            </div>
          </div>

          <!-- <div class="col col-md-2 col-lg-2"></div> -->

        </div>

        <div class="row">
          <div class="col-sm-12">
            <button type="submit" class="btn btn-primary me-1">Submit</button>
            <button @click="reset" type="reset" class="btn btn-outline-secondary">Reset</button>
          </div>
        </div>

      </div>

    </form>
  </div>
</template>

<script setup>
import {defineProps, ref, watch} from "vue";

const props = defineProps({
  items: {
    type: Array
  }
})

const phoneNumbers = ref([
  {phone: ''}
])

const addMore = () => {
  phoneNumbers.value.push({phone: ''})
}

const remove = index => {
  phoneNumbers.value.splice(index, 1)
}

const reset = () => {
  phoneNumbers.length = 0;
  addMore();
}

watch(() => props.items, (newFormData) => {
  formDataSet();
});

const formDataSet = () => {
  if (props.items.length) {
    phoneNumbers.value = props.items
  }
}
</script>

<style scoped>

</style>
