<template>
  <div class="card-header border-bottom pt-1 pb-1">
    <h4 class="card-title">Supervisor</h4>
  </div>

  <div class="card-body mt-2">
    <form class="invoice-repeater" @submit.prevent="handleSubmit">
      <div>

        <div v-for="(activity, index) in supervisors" :key="index" class="row">
          <div class="col-md-2 col-12">
            <div class="mb-1">
              <v-select
                  placeholder="Select Employee"
                  v-model="activity.supervisor"
                  :options="contacts"
                  label="name"
                  :reduce="name => name.id"
              />
            </div>
          </div>
          <div class="col-md-2 col-12">
            <div class="mb-1">
              <input  v-model="activity.supervisor_effective_date" type="date" class="form-control" id="activity"
                     placeholder="Effective Date"/>
            </div>
          </div>
          <div class="col-md-2 col-12">
            <div class="mb-1">
              <v-select
                  placeholder="Select Direct Supervisor"
                  v-model="activity.direct_supervisor"
                  :options="status"
                  :reduce="name => name.value"
                  label="name"
              />
            </div>
          </div>
          <div class="col-md-2 col-12">
            <div class="mb-1">
              <input v-model="activity.supervisor_remarks" type="text" class="form-control" id="activity"
                     placeholder="Remarks"/>
            </div>
          </div>
          <div class="col-md-2 col-12 p_left_0">

            <div class="mb-1 _skill_out_visibility">

              <button v-if="supervisors.length === (index + 1)" @click="addMore"
                      class="btn btn-outline-primary text-nowrap px-1 button_icon" type="button">
                <i class="fa fa-plus"></i>
              </button>

              <button v-else @click="remove(index)"
                      class="btn btn-outline-danger text-nowrap px-1 button_icon" type="button">
                <i class="fa fa-trash"></i>
              </button>

            </div>

            <div class="mb-1 d-grid gap-2 d-none _skill_btn_visibility">

              <button v-if="supervisors.length === (index + 1)" @click="addMore"
                      class="btn btn-primary text-nowrap px-1 button_icon" type="button">
                <i class="fa fa-plus"></i>
              </button>

              <button v-else @click="remove(index)"
                      class="btn btn-danger text-nowrap px-1 button_icon" type="button">
                <i class="fa fa-trash"></i>
              </button>

            </div>

          </div>

        </div>

        <div class="row">
          <div class="col-sm-12">
            <button type="submit" class="btn btn-primary me-1">Submit</button>
            <button @click="reset" type="reset" class="btn btn-outline-secondary">Reset</button>
          </div>
        </div>

      </div>

    </form>
  </div>
</template>

<script setup>

import {defineProps, inject, onMounted, ref, watch} from "vue";
import handleContact from '@/services/modules/contact'
import {useRoute} from "vue-router";
import handleHrPayroll from "@/services/modules/hrPayroll/hrPayroll";
import { defineEmits } from 'vue';

const emit = defineEmits(['storeContact']);
const props = defineProps({
  items: {
    type: Array
  }
})

const route = useRoute()
let contacts = ref([])
let status = ref([])
const showError = inject('showError');
const showSuccess = inject('showSuccess')

const { fetchContactProfiles } = handleContact()
const {
  fetchTypes,
} = handleHrPayroll()

const supervisors = ref([
  {
    supervisor: '',
    supervisor_effective_date: '',
    direct_supervisor: 'no',
    supervisor_remarks: '',
  }
])

const handleSubmit = () => {
  if (!supervisors.value || supervisors.value.length === 0) {
    showError('There is no data found!');
    return;
  }

  const invalidSupervisors = supervisors.value.filter(
      (supervisor) => !supervisor.supervisor
  );

  if (invalidSupervisors.length > 0) {
    showError('Some supervisors are missing employee!');
    return;
  }

  const supervisorIds = supervisors.value.map((supervisor) => supervisor.supervisor);
  const duplicateIds = supervisorIds.filter((id, index) => supervisorIds.indexOf(id) !== index);

  if (duplicateIds.length > 0) {
    showError('Duplicate supervisor found:');
    return;
  }

  emit('storeContact', {'items':supervisors.value})
}

const addMore = () => {
  supervisors.value.push({
    supervisor: '',
    supervisor_effective_date: '',
    direct_supervisor: 'no',
    supervisor_remarks: '',
  })
}

const remove = index => {
  supervisors.value.splice(index, 1)
}

const reset = () => {
  supervisors.value.length = 0;
  addMore();
}

watch(() => props.items, (newFormData) => {
  formDataSet();
});

const formDataSet = () => {
  if (props.items.length){
    supervisors.value = props.items.map(item => ({
      ...item,
      supervisor: parseInt(item.supervisor),
      supervisor_effective_date: item.supervisor_effective_date,
      direct_supervisor: item.direct_supervisor,
      supervisor_remarks: item.supervisor_remarks,
    }));
  }
}

const getTypes = async () => {
  let query = getQuery();
  await fetchTypes(query).then(res => {
    if (!res.status){
      status.value = [];
      return
    }

    if (res.data && res.data.carriableType) {
       status.value = res.data.carriableType;
    }

  }).catch(err => {
    console.log(err)
  })
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

onMounted(() => {
  const query = getQuery()
  const contactQuery = query + '&type=employee'
  const contactRes   = fetchContactProfiles(contactQuery)
  Promise.all([
    getTypes(),
    contactRes.then(res => {
      if (res.data) contacts.value = res.data
    }),

  ]).finally(() => {
  })
})

</script>

<style scoped></style>
