<template>
  <div class="card-header border-bottom pt-1 pb-1">
    <h4 class="card-title">Designation</h4>
  </div>

  <div class="card-body mt-2">
    <form class="invoice-repeater" @submit.prevent="$emit('storeContact', {'items':employment_designation})">
      <div class="row">
        <div class="col-sm-6 offset-md-3 col-12">
          <div class="row"  v-for="(des, index) in employment_designation">
            <div class="col-12">
              <div class="mb-1 row">
                <label class="col-form-label">Designation</label>
                <v-select
                    placeholder="Select Designation"
                    v-model="des.employment_designation"
                    :options="designationList"
                    label="name"
                    :reduce="name => name.id"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="mb-1 row">
                <label class="col-form-label">Effective Date</label>
                <input type="date" id="full-name" required class="form-control" v-model="des.employment_designation_effective_date"
                       placeholder="Effective Date" />
              </div>
            </div>
            <div class="col-12">
              <div class="mb-1 row">
                <label class="col-form-label">Remarks</label>
                <input type="text" id="full-name" class="form-control" v-model="des.employment_designation_remarks"
                       placeholder="Remarks" />
              </div>
            </div>

            <div class="col-sm-6 offset-sm-3">
              <button type="submit" class="btn btn-primary me-1">Submit</button>
              <button @click="reset" type="reset" class="btn btn-outline-secondary">Reset</button>
            </div>

          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import {defineProps, onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import handleDesignation from "@/services/modules/procurement/organization-settings/designation";

const props = defineProps({
  items: {
    type: Array
  }
})

const route = useRoute()
const { fetchDesignationList } = handleDesignation()

let designationList = ref([])

const employment_designation  = ref([
    {
      employment_designation: '',
      employment_designation_effective_date:'',
      employment_designation_remarks:'',
    }
])

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

const getDesignationList = async () => {
  let query = getQuery();

  designationList.value = []

  await fetchDesignationList(query).then(res => {
    if (!res.status){
      designationList.value = []
      return
    }
    designationList.value = res.data;
  }).catch(err => {
    console.log(err)
  })
}

const reset = () => {
  employment_designation.value.length = 0;
}

watch(() => props.items, (newFormData) => {
  formDataSet();
});

const formDataSet = () => {
    if (props.items.length){
      employment_designation.value = props.items.map(item => ({
        ...item,
        employment_designation: parseInt(item.employment_designation),
        employment_designation_effective_date: item.employment_designation_effective_date,
        employment_designation_remarks: item.employment_designation_remarks,
      }));
    }
}

onMounted(() => {
  Promise.all([
    getDesignationList()
  ])
})

</script>
