<template>
  <div class="card-header border-bottom pt-1 pb-1">
    <h4 class="card-title">Extra Activity</h4>
  </div>

  <div class="card-body mt-2">
    <form class="invoice-repeater" @submit.prevent="$emit('storeContact', {'items':extraActivity})">
      <div>

        <div v-for="(activity, index) in extraActivity" :key="index" class="row">
          <div class="col-md-4 col-12">
            <div class="mb-1">
              <input v-model="activity.extra_activity" type="text" class="form-control" id="activity"
                     placeholder="Extra activity"/>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <div class="mb-1">
              <input v-model="activity.details" type="text" class="form-control" id="activity"
                     placeholder="Extra activity details"/>
            </div>
          </div>
          <div class="col-md-2 col-12 p_left_0">

            <div class="mb-1 _skill_out_visibility">

              <button v-if="extraActivity.length === (index + 1)" @click="addMore"
                      class="btn btn-outline-primary text-nowrap px-1 button_icon" type="button">
                <i class="fa fa-plus"></i>
              </button>

              <button v-else @click="remove(index)"
                      class="btn btn-outline-danger text-nowrap px-1 button_icon" type="button">
                 <i class="fa fa-trash"></i>
              </button>

            </div>

            <div class="mb-1 d-grid gap-2 d-none _skill_btn_visibility">

              <button v-if="extraActivity.length === (index + 1)" @click="addMore"
                      class="btn btn-primary text-nowrap px-1 button_icon" type="button">
                <i class="fa fa-plus"></i>
              </button>

              <button v-else @click="remove(index)"
                      class="btn btn-danger text-nowrap px-1 button_icon" type="button">
                <i class="fa fa-trash"></i>
              </button>

            </div>

          </div>

        </div>

        <div class="row">
          <div class="col-sm-12">
            <button type="submit" class="btn btn-primary me-1">Submit</button>
            <button @click="reset" type="reset" class="btn btn-outline-secondary">Reset</button>
          </div>
        </div>

      </div>

    </form>
  </div>
</template>

<script setup>

import {defineProps, ref, watch} from "vue";

const props = defineProps({
  items: {
    type: Array
  }
})


const extraActivity = ref([
  {
    extra_activity: '',
    details: ''
  }
])

const addMore = () => {
  extraActivity.value.push({
    extra_activity: '',
    details: ''
  })
}

const remove = index => {
  extraActivity.value.splice(index, 1)
}

const reset = () => {
  extraActivity.value.length = 0;
  addMore();
}

watch(() => props.items, (newFormData) => {
  formDataSet();
});

const formDataSet = () => {
  if (props.items.length){
    extraActivity.value = props.items
  }
}

</script>

<style scoped></style>
