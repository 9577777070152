<template>
  <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle"
       style="display: block; background: #1c1d1c33">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between px-1 my-1 align-item-center">
              <h1 class="mb-0">{{ isEdit ? 'Update' : 'Add' }} {{ title }}</h1>
              <button
                  @click="toggleModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
        <hr class="mt-0">

        <div class="pb-2">
          <!-- form -->
          <vForm @submit="submit" :validation-schema="schema">
            <div class="row gy-2 px-2">
              <div class="col-12">
                <label class="align-middle">Leave Approver</label>
                <v-select
                    placeholder="Select Employee"
                    v-model="formData.leave_approver_contact_profile_id"
                    :options="contacts"
                    label="name"
                    :reduce="name => name.id"
                />
              </div>
              <div v-if="isEdit" class="col-12">
                <label class="align-middle">Status</label>
                <v-select
                    placeholder="Select Policy"
                    v-model="formData.status"
                    :options="status"
                    :reduce="name => name.value"
                    label="name"
                />
              </div>

            </div>

            <div class="col-12 text-center">
              <hr class="mt-2">
              <button :disabled="loader" type="submit"
                      class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                <div v-if="loader" class="spinner-border spinner-border-sm text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                {{ isEdit ? 'Update' : 'Submit' }}
              </button>
              <button @click.prevent="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                Cancel
              </button>
            </div>
          </vForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import handleContact from '@/services/modules/contact'
import {useRoute} from "vue-router";
import handleHrPayroll from "@/services/modules/hrPayroll/hrPayroll";

const props = defineProps({
  formData: Object,
  title: {
    type: String,
    default: ''
  },
  isEdit: {
    type: Boolean,
    default: false
  }
})

const $emit = defineEmits(['onSubmit', 'onClose'])

const route = useRoute()
let isOpen = ref(false)
const loader = ref(false)
const schema = ref({})
let contacts = ref([])
const status = ref([]);

const {
  fetchTypes,
} = handleHrPayroll()
const { fetchContactProfiles } = handleContact()
const toggleModal = (isEdit = false) => {
  isOpen.value = !isOpen.value
  if (!isEdit) {
    $emit('onClose')
  }
}

const submit = () => {
  $emit("onSubmit")
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

const getTypes = async () => {
  let query = getQuery();
  await fetchTypes(query).then(res => {
    if (!res.status){
      status.value = [];
      return
    }

    if (res.data && res.data.status) {
      status.value = res.data.status.filter(item =>
          ["active", "inactive"].includes(item.value)
      );
    }

  }).catch(err => {
    console.log(err)
  })
}

onMounted(() => {
  loader.value=true
  const query = getQuery()
  const contactQuery = query + '&type=employee'
  const contactRes   = fetchContactProfiles(contactQuery)
  Promise.all([
    getTypes(),
    contactRes.then(res => {
      if (res.data) contacts.value = res.data
    }),

  ]).finally(() => {
    loader.value = false
  })
})

defineExpose({toggleModal, loader})

</script>
