<template>
  <div class="card-header border-bottom pt-1 pb-1">
    <h4 class="card-title">Leave Approver</h4>
    <div>
      <button @click="openModal" type="button" class="btn btn-primary me-1">+ Add new</button>
    </div>
  </div>

  <div class="card-body mt-2">
    <div class="card p-2" style="min-height: 200px">
      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th>Leave Approver</th>
            <th>Designation</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in tableItems" :key="i">
            <td>{{ item.leave_approver_name }}</td>
            <td>{{ item.leave_approver_designation }}</td>
            <td>{{ item.status }}</td>
            <td>
              <button class="btn btn-primary btn-sm"
                      @click="goToEdit(item)"
                      style="cursor: pointer"
              >
                <i class="fas fa-eye"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <p v-if="tableItems.length < 0" class="text-center mt-5">No data</p>
      </div>
    </div>
  </div>

  <LeaveApproverModal
      :title="title"
      :isEdit="isEdit"
      ref="registerTypeModalRef"
      :formData="formData"
      @onSubmit="handleSubmit"
      @onClose="clearModal"
  />

</template>

<script setup>
import {computed, onMounted, ref, inject} from "vue";
import handleHrPayroll from "@/services/modules/hrPayroll/hrPayroll";
import LeaveApproverModal from "@/views/page/company/hr-payroll/employee-register/tabs/person/LeaveApproverModal.vue";
import {useRoute, useRouter} from "vue-router";

const router = useRouter()
const route = useRoute()
const title = ref('Leave Approver')
const tableItems = ref([]);
const isEdit = ref(false)
const registerTypeModalRef = ref(null);
const showError = inject('showError');
const showSuccess = inject('showSuccess')
const loader = ref(false)
const companyId = computed(() => {
  return route.params.companyId
})
const loading = ref(false)
let leaveApproverData = {}
let leaveApproverEditData = ref({})

const contactId = computed(() => route.query.contactId)

const formData = ref({
  company_id: companyId,
  status: 'active',
  contact_profile_id: '',
  leave_approver_contact_profile_id:'',
})

const openModal = () => {
  isEdit.value = false
  registerTypeModalRef.value.toggleModal()
}

const clearModal = () => {
  formData.value = {
    company_id: companyId,
    status: 'active',
    contact_profile_id:'',
    leave_approver_contact_profile_id:'',
  }
}

const {
  fetchLeaveApproverList,
  createLeaveApprover,
  updateLeaveApprover,
  fetchSingleLeaveApprover
} = handleHrPayroll()

const handleSubmit =  () => {
  if (isEdit.value){
    return updateLA()
  }
  return createLA();
}

const createLA = () =>{
  registerTypeModalRef.value.loading = true;
  leaveApproverData.company_id  = companyId.value;
  leaveApproverData.status = "active";
  leaveApproverData.contact_profile_id = route.query.contactId;
  leaveApproverData.leave_approver_contact_profile_id = formData.value.leave_approver_contact_profile_id;

  createLeaveApprover(leaveApproverData, getQuery())
      .then(res => {
        if (!res.status) {
          showError(res.message)
          return
        }
        showSuccess(res.message)
        registerTypeModalRef.value.toggleModal()
        getLeaveApprover()
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        loading.value = registerTypeModalRef.value.loading = false;
  })
}

const updateLA = () =>{
  registerTypeModalRef.value.loading = true;
  leaveApproverData.company_id  = companyId.value;
  leaveApproverData.status = formData.value.status;
  leaveApproverData.contact_profile_id = route.query.contactId;
  leaveApproverData.leave_approver_contact_profile_id = formData.value.leave_approver_contact_profile_id;
  leaveApproverData.id = formData.value.id;

  updateLeaveApprover(leaveApproverData,leaveApproverData.id, getQuery())
      .then(res => {
        if (!res.status) {
          showError(res.message)
          return
        }
        showSuccess(res.message)
        registerTypeModalRef.value.toggleModal()
        getLeaveApprover()
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
      loading.value = registerTypeModalRef.value.loading = false;
  })
}

const getLeaveApprover = async () => {
  const query = getQuery();
  loader.value = true
  tableItems.value = []

  await fetchLeaveApproverList(query).then(res => {
    if (!res.status){
      return
    }

    tableItems.value = res.data;
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loader.value = false
  })
}

const getQuery = () => {
  let query  = `?company_id=${companyId.value}`;
  query += '&contact_profile_id='+ route.query.contactId
  query += '&is_all=true'
  return query
}

function setFormData() {
  formData.value.id =  leaveApproverEditData.value.id;
  formData.value.leave_approver_contact_profile_id = leaveApproverEditData.value.leave_approver_contact_profile_id;
  formData.value.status = leaveApproverEditData.value.status;
}

const goToEdit = (item) => {
  isEdit.value = true
  formData.value = item
  registerTypeModalRef.value?.toggleModal(isEdit.value)
  const fetchSingleApprover = fetchSingleLeaveApprover(item.id, getQuery());
  fetchSingleApprover.then(res2 => {
    leaveApproverEditData.value = res2.data;
    setFormData();
  });
}

onMounted(() => {
  Promise.all([
    getLeaveApprover(),
  ])
})

</script>

<style scoped></style>
